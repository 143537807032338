import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationService } from '../notification/notification.service';

@Injectable({
    providedIn: 'root',
})
export class RoomService {
    /**
     * The object containing the description, settings, role and token for the room.
     *  - Sets the [description]{@link RoomService#description}, [settings]{@link RoomService#settings}, [token]{@link RoomService#token}, [taskUrl]{@link RoomService#taskUrl}, and [taskInfo]{@link RoomService#taskInfo} getters
     *  - Used in the [AuthGuard]{@link AuthGuard} to check if there is room data
     *  - Set in [getRoomToken]{@link TaskSetupComponent#getRoomToken} in the TaskSetupComponent
     * - Set in [getRoomToken]{@link WelcomeComponent#getRoomToken} in the WelcomeComponent
     */
    roomData: any = {};

    /**
     * Sets the roomId getter
     */
    private _roomId: string = '';

    isAiModerated = new BehaviorSubject(false);

    /**
     * Gets the roomId from the route params  of the activated route, in the welcome component.
     *  - Set in [setRoomId()]{@link TaskSetupComponent#setRoomId} in the TaskSetupComponent
     *  - Set in [setRoomId()]{@link WelcomeComponent#setRoomId} in the WelcomeComponent
     *  - Used in [updateUserDetails()]{@link UserService#updateUserDetails} in the UserService
     *  - Sets the [roomId]{@link SetupComponent#roomId} in the SetupComponent
     *  - Sets the [roomId]{@link TaskSetupComponent#roomId} in the TaskSetupComponent
     *  - Sets the [roomId]{@link WaitingRoomComponent#roomId} in the WaitingRoomComponent
     *  - Sets the [roomId]{@link WelcomeComponent#roomId} in the WelcomeComponent
     */
    public get roomId(): string {
        return this._roomId;
    }

    /**
     * Sets the roomId getter
     */
    public set roomId(value: string) {
        this._roomId = value;
    }

    /**
     * Gets the description from the [roomData]{@link RoomService#roomData} object.
     *  - Used in [updateUserDetails]{@link UserService#updateUserDetails}
     *  - Sets the [description]{@link RoomComponent#description} getter in the RoomComponent
     */
    get description(): string {
        return this.roomData.description;
    }

    /**
     * Gets the settings from the [roomData]{@link RoomService#roomData} object.
     *  - Possibly not being used.
     */
    get settings(): any {
        return this.roomData.settings;
    }

    /**
     * Gets the token from the [roomData]{@link RoomService#roomData} object.
     *  - Sets the [userConfig]{@link StreamProviderService#userConfig} in the StreamProviderService
     *  - Used in [getCallObject()]{@link StreamProviderService#getCallObject}
     */
    get token(): string {
        return this.roomData.token;
    }

    /**
     * Gets the task_url from the  [roomData]{@link RoomService#roomData} object.
     *  - Used to set [taskUrl]{@link RoomComponent#taskUrl} in the RoomComponent
     */
    get taskUrl(): string {
        return this.roomData.task_url;
    }

    /**
     * Gets the task_info from the [roomData]{@link RoomService#roomData} object.
     *  - Used to set [taskInfo]{@link RoomComponent#taskInfo} in the RoomComponent
     */
    get taskInfo(): string {
        return this.roomData.task_info;
    }

    /**
     * BehaviourSubject which is used to toggle the sidebar
     *  - Toggled in the [toggleRoomInfo()]{@link RoomService#toggleRoomInfo} and [toggleChat()]{@link RoomService#toggleChat} functions
     *  - Sets [sidebarActive]{@link RoomComponent#sidebarActive} getter in the RoomComponent
     *  - Subscribed to in [setupEvents]{@link RoomControlsComponent#setupEvents} in the RoomControlsComponent
     */
    sideBarActive = new BehaviorSubject(false);

    /**
     * Boolean which is used to toggle the roomInfo
     *  - Used in the [toggleRoomInfo()]{@link RoomService#toggleRoomInfo} function
     *  - Set to false in the [toggleChat()]{@link RoomService#toggleChat} function
     *  - Sets [roomInfoActive]{@link RoomComponent#roomInfoActive} in the RoomComponent
     *  - Sets [roomInfoActive]{@link RoomControlsComponent#roomInfoActive} in the RoomControlsComponent
     */
    roomInfoEnabled = false;

    /**
     * Boolean which is used to toggle the chat
     *  - Used in the [toggleChat()]{@link RoomService#toggleChat} function
     *  - Set to false in the [toggleRoomInfo()]{@link RoomService#toggleRoomInfo} function
     * - Sets [chatActive]{@link RoomComponent#chatActive} in the RoomComponent
     * - Sets [chatActive]{@link RoomControlsComponent#chatActive} in the RoomControlsComponent
     */
    chatEnabled = false;

    /**
     * BehaviourSubject which is used to toggle the active settings
     *  - Toggled in the [toggleSettings()]{@link RoomService#toggleSettings} function
     *  - Sets [settingsActive]{@link RoomComponent#settingsActive} in the RoomComponent
     */
    settingsActive = new BehaviorSubject(false);

    /**
     * Boolean which is used to determine if the task is task based
     *  - Set in [ngOnInit()]{@link TaskSetupComponent#ngOnInit} in the TaskSetupComponent
     *  - Used in [addParticipant()]{@link ParticipantService#addParticipant} in the ParticipantService
     *  - Used in [ngOnInit()]{@link CompleteComponent#ngOnInit} in the CompleteComponent
     *  - Sets [isTaskBased]{@link RoomComponent#isTaskBased} getter in the RoomComponent
     *  - Sets [isTaskBased]{@link RoomControlsComponent#isTaskBased} in the RoomControlsComponent
     *  - Sets [isTaskBased]{@link TaskSetupComponent#isTaskBased} in the TaskSetupComponent
     */
    isTaskBased = false;

    startTask = new Subject();

    popupError = new BehaviorSubject(false);

    constructor(private notificationService: NotificationService) {}

    /**
     *  - Toggles the [roomInfoEnabled]{@link RoomService#roomInfoEnabled} boolean (which is used to display the room info)
     *  - Sets [chatEnabled]{@link RoomService#chatEnabled} boolean to false to hide the chat
     *  - If the side bar is not active/room info is not enabled, it toggles [sideBarActive]{@link RoomService#sideBarActive}
     *  - Called in [toggleRoomInfo()]{@link RoomControlsComponent#toggleRoomInfo} in the RoomControlsComponent
     *  - Called in [close()]{@link RoomDetailsComponent#close} in the RoomDetailsComponent
     * @returns void if the sidebar is active, it room info is enabled
     */
    toggleRoomInfo() {
        this.roomInfoEnabled = !this.roomInfoEnabled;
        this.chatEnabled = false;

        if (this.sideBarActive.value && this.roomInfoEnabled) {
            return;
        }

        this.sideBarActive.next(!this.sideBarActive.value);
    }

    /**
     *  - Toggles the [chatEnabled]{@link RoomService#chatEnabled} boolean
     *  - Sets [roomInfoEnabled]{@link RoomService#roomInfoEnabled} boolean to false
     *  - If the side bar is not active/chat is not enabled, it toggles [sideBarActive]{@link RoomService#sideBarActive}
     *  - Called in [toggleChat()]{@link RoomControlsComponent#toggleChat} in the RoomControlsComponent
     * @returns void if the sidebar is active and chat is enabled
     */
    toggleChat() {
        this.chatEnabled = !this.chatEnabled;
        this.roomInfoEnabled = false;

        if (this.sideBarActive.value && this.chatEnabled) {
            return;
        }

        this.sideBarActive.next(!this.sideBarActive.value);
    }

    /**
     * Toggles the [settingsActive]{@link RoomService#settingsActive} BehaviourSubject
     *  - Called in [toggleSettings()]{@link RoomControlsComponent#toggleSettings} in the RoomControlsComponent
     * - Called in [closeSettings()]{@link SetupComponent#closeSettings} in the SetupComponent
     * @param value - boolean
     */
    toggleSettings(value?: boolean) {
        this.settingsActive.next(value ? value : !this.settingsActive.value);
    }
}
