import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    /**
     * BehaviorSubject to update the number of messages
     *  - Updated in [addMessage()]{@link NotificationService#addMessage} with the number of unread messages
     *  - Updated in [clearMessages()]{@link NotificationService#clearMessages} with 0
     *  - Sets the [messageCount]{@link RoomControlsComponent#messageCount} getter in the RoomControlsComponent
     *  - Subscribed to in [setupEvents()]{@link RoomControlsComponent#setupEvents} in the RoomControlsComponent
     */
    messagesUpdated: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    chatMessagesUpdated: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    dmMessagesUpdated: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    insightsMessagesUpdated: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    /**
     * Array of unread messages
     *  - Added to in [addMessage()]{@link NotificationService#addMessage} with the message
     *  - Updates the [messagesUpdated]{@link NotificationService#messagesUpdated} BehaviorSubject in [addMessage()]{@link NotificationService#addMessage}
     *  - Used as a check in [clearMessages()]{@link NotificationService#clearMessages} to see if there are any messages to clear, and if not, sets to an empty array
     */
    unreadChatMessages: string[] = [];

    unreadDmMessages: string[] = [];

    unreadInsightsMessages: string[] = [];

    constructor() {}

    updateMessagesCount() {
        this.messagesUpdated.next(this.unreadChatMessages.length + this.unreadDmMessages.length + this.unreadInsightsMessages.length);
    }
    /**
     * Adds a message to the [unreadMessages]{@link NotificationService#unreadMessages} array and updates the [messagesUpdated]{@link NotificationService#messagesUpdated} BehaviorSubject
     *  - Called in [addChatMessage()]{@link ChatService#addChatMessage} in the ChatService
     * @param message - The message to add to the {@link #unreadMessages|unreadMessages} array
     */
    addChatMessage(message: string) {
        this.unreadChatMessages.push(message);
        this.chatMessagesUpdated.next(this.unreadChatMessages.length);
        this.updateMessagesCount();
    }

    addDmMessage(message: string) {
        this.unreadDmMessages.push(message);
        this.dmMessagesUpdated.next(this.unreadDmMessages.length);
        this.updateMessagesCount();
    }

    addInsightsMessage(message: string) {
        this.unreadInsightsMessages.push(message);
        this.insightsMessagesUpdated.next(this.unreadInsightsMessages.length);
        this.updateMessagesCount();
    }

    clearMessages(type: 'chat' | 'dm' | 'insights') {
        if (type === 'chat' && this.unreadChatMessages.length) {
            this.unreadChatMessages = [];
            this.chatMessagesUpdated.next(0);
        } else if (type === 'dm' && this.unreadDmMessages.length) {
            this.unreadDmMessages = [];
            this.dmMessagesUpdated.next(0);
        } else if (type === 'insights' && this.unreadInsightsMessages.length) {
            this.unreadInsightsMessages = [];
            this.insightsMessagesUpdated.next(0);
        }
        this.updateMessagesCount();
    }

    /**
     * Clears all messages from the [unreadMessages]{@link NotificationService#unreadMessages} array and updates the [messagesUpdated]{@link NotificationService#messagesUpdated} BehaviorSubject
     *  - Called in [setupEvents()]{@link RoomControlsComponent#setupEvents}
     *  - Called in [toggleChat()]{@link RoomControlsComponent#toggleChat}
     * @returns void if there are no unread messages
     */
    clearChatMessages() {
        this.clearMessages('chat');
    }

    clearDmMessages() {
        this.clearMessages('dm');
    }

    clearInsightsMessages() {
        this.clearMessages('insights');
    }
}
